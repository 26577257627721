const STORAGE_NAME = "BagBookmarks";

export class Bookmarks {
    #graphics = [];
    addGraphic(id) {
        id = parseInt(id);
        if (!this.hasGraphic(id)) this.#graphics.push(id);
        this.#processChange();
    }
    clearGraphics() {
        this.#graphics = [];
        this.#processChange();
    }
    hasGraphic(id) {
        id = parseInt(id);
        return this.#graphics.includes(id);
    }
    load() {
        let json = null;
        try {
            json = window.localStorage.getItem(STORAGE_NAME);
        }
        catch (e) {} // localStorage doesn't work in incognito mode so ignore
        if (json !== null) {
            this.#graphics = JSON.parse(json);
        }
        this.#processChange();
    }
    graphics() {
        return this.#graphics;
    }
    removeGraphic(id) {
        id = parseInt(id);
        this.#graphics = this.#graphics.filter((gid) => gid !== id);
        this.#processChange();
    }
    toggleGraphic(id) {
        id = parseInt(id);
        if (this.hasGraphic(id)) {
            this.removeGraphic(id);
        }
        else {
            this.addGraphic(id);
        }
    }
    #processChange() {
        this.#save();
    }
    #save() {
        try {
            window.localStorage.setItem(STORAGE_NAME, JSON.stringify(this.#graphics));
        }
        catch (e) {} // localStorage doesn't work in incognito mode so ignore
    }
}
