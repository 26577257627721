import env from '../environment.js';
import { fluentButton, fluentAnchor, fluentAnchoredRegion, fluentCard, fluentTextField, fluentTooltip, fluentMenu, fluentMenuItem, fluentDivider, provideFluentDesignSystem, accentBaseColor, SwatchRGB } from '@fluentui/web-components';
import { BackEnd } from '../backend.js';
import { Bookmarks } from '../bookmarks.js';
import { Chunking } from '../chunking.js';
import { OfficeWrapper } from '../office-wrapper.js';
import { Recent } from '../recent.js';
import t from '../strings.js';
import { graphicTypeMappings } from '../graphic-types.js';

const pages = [
  { name: "search",           isDialog: false },
  { name: "results",          isDialog: false },
  { name: "graphic-details",  isDialog: false },
  { name: "not-licensed",     isDialog: true },
  { name: "validate-license", isDialog: true },
  { name: "expired-license",  isDialog: true}
];

var backEnd;
var scrollPos;
let bookmarks = new Bookmarks();
var recent = new Recent();
let dialogStack = [];
let selectionMode = false;
let lastSelectedIndex = null;
let isSearchBoxOnResultsPage = false;
let searchBoxPlaceholderText = null;

Office.onReady(async (info) => {
  if (info.host === Office.HostType.PowerPoint) {
    backEnd = new BackEnd();
    provideFluentDesignSystem().register(
      fluentButton(),
      fluentAnchor(),
      fluentAnchoredRegion(),
      fluentCard(),
      fluentTextField(),
      fluentTooltip(),
      fluentMenu(),
      fluentMenuItem(),
      fluentDivider()
    );
    accentBaseColor.withDefault(SwatchRGB.from({ r: 3/255, g: 118/255, b: 160/255 }));
    $(document).keydown((event) => {
       if (event.code == "KeyS" && event.altKey && event.ctrlKey && event.shiftKey) {
        selectionMode = !selectionMode;
        updateSelectionMode();
        event.preventDefault(); 
      } 
    });
    $("#home-button").click(() => navigateToPage("search"));
    $("#copy-selected-ids-button").click(async () => await onClickCopySelectedIds());
    $("#app-body").show();
    $(".buyNowURL").click(() => openURL(t.urls.buyNow));
    //TODO: Add a click event to enable user to sign in
    $(".signIn").click();
    $(".contactSupport").click(() => openURL(t.urls.contactSupport));
    $("#menu-how-to").click(() => openURL(t.urls.howTo));
    $("#menu-button").click(toggleMenu);
    $("#nav-button").click(async () => await onClickBackToSearchResults());
    $("#cancel-indicator").click(clearSearchText);
    $("#search-icon").click(async () => await searchDatabase());
    $("#search-box-input").keydown(async (event) => event.which === 13 ? await searchDatabase() : null);
    $("#copy-id-to-clipboard").click(async () => await copyIdtoClipboard());
    $("#copy-id-to-clipboard-alert").css("display","none");
    $("#btnShowHide").click(toggleKeywords);
    $("#btn-filter").click(() => toggleFilter());
    $("#icon-close-page").click(closeDialog);
    $("#chunk-bullet").click(() => Chunking.chunkSelectionBy("bullet"));
    $("#chunk-paragraph").click(() => Chunking.chunkSelectionBy("paragraph"));
    $("#chunk-sentence").click(() => Chunking.chunkSelectionBy("sentence"));
    $("fluent-menu-item").click(onClickMenuItem);
    $("#menu-license-agreement").click(() => openURL(t.urls.licenseAgreement));
    $("#suggestions-button").click(() => openURL(t.urls.suggestGraphics));
    $(".filterLabel").click(onClickSearchFilter);
    $("#filter-all").attr("checked", "checked");
    $('#search-box-input').click(onClickSearchInput);
    $('#search-box-input').on("input",searchInputChanged);
    $('#clear-recent').click(() => recent.clearSearches());
    $("#menu-item-recently-inserted").click(async () => await onClickRecentlyInsertedGraphics());
    $("#btn-authn-test").click(async () => await backEnd.authnTest());
    $("#btn-activate-license").click(async () => await onClickActivateLicense());
    $("img").attr("draggable", "false");
    $(".cantFindGraphic").empty().html(t.cantFindGraphic);
    setLicensed(await backEnd.whoami());
    $('#new-graphics-button').click(async () => await onClickWhatsNew());
    $("#bookmarks-button").click(async () => await onClickBookmarks());
    setShowKeywords(false);
    showRandomTips();
    window.setLicensed = setLicensed;
    window.bagdebug = () => { $(".debugHide").show(); };
    recent.onChange(updateRecentUI);
    recent.load();
    $("#section-loading").hide();
    $("#section-main").show();
    populateGraphicTypes("keyword-area-graphic-types");
    setMaxHeightByRows(4, $("#keyword-area-graphic-types"));
    $("#btnShowHideGraphicTypes").click(function() {
      toggleGraphicTypesExpanded(4);
    });
    $("#btn-bookmark-graphic").click(function() {
      const id = $("#btn-insert-graphic").attr("data-graphicid");
      bookmarks.toggleGraphic(id);
      updateBookmarkIconAndText(id);
    });
    $("#clear-bookmarks").click(async () => {
      bookmarks.clearGraphics();
      await searchDatabase();
    });
    bookmarks.load();
    //This is a bit of a hack because autocomplete="off" doesn't seem to work on fluent text areas
    try {
      $("#search-box-input").get()[0].shadowRoot.querySelector("input").autocomplete = "off";
    } catch (error) {
        console.error("Error while disabling search autocomplete:", error);
    }
  }
});
//show or hide elements depending on whether user is licensed
function setLicensed(userData) {
  //can be in one of four states
  //"Licensed" - user logged into PowerPoint and has a valid licence.
  if (userData && userData.subscription && !userData.subscription.isExpired) {
    setAnyLicensed(userData);
    $("#btn-insert-graphic").off("click", onClickInsertGraphicNotLicensed).on("click", onClickInsertGraphic);
    $("#chunk-menu-button").click(toggleChunkMenu);
    $("#tooltip-chunk-text").empty().html(t.chunkLicensed);
    $("#menu-item-expiry-text").empty().text("Expires: ")
    if (Chunking.isSupported() === false) {
      $("#chunk-menu-button").off("click",toggleChunkMenu);
      $("#chunk-tooltip").css("display","block");
      $("#tooltip-chunk-text").empty().html(t.chunkUnsupported);
    }
  }
  //Expired license - user logged into PowerPoint and does not have a valid licence.
  else if (userData && userData.subscription && userData.subscription.isExpired){
    setAnyLicensed(userData);
    $("#btn-insert-graphic").off("click", onClickInsertGraphic).off("click", onClickInsertGraphicNotLicensed).on("click", onClickInsertGraphicExpiredLicense);
    $("#menu-item-expiry-text").empty().text("Expired: ").addClass("expiredText")
    $("#menu-subscription").click(onClickExpiredMenuItem).removeClass("linkAuto").addClass("linkHasPointer");
    $("#icon-close-expired-license-page").click(closeDialog);
  }
  //"Authenticated" - user logged into PowerPoint but there is no licence or the subscription has expired
  else if (userData && !userData.subscription) {
    setAuthenticated();
    setAnyUnlicensed();
    $("#menu-activate-license").show().click(() => navigateToPage("validate-license"));
    $("#menu-item-expiry-text").empty().text("Expires: ").removeClass("expiredText");
    $("#menu-second-divider").show();
    $("#avatar").removeClass("avatarUnlicensed").text(() => getInitials(userData.name)).addClass("avatarLicensed");
    $("#existing-user-message").empty().text("Activate your license.").addClass("link").off("click", openURL).click(() => navigateToPage("validate-license"))
  }

  //user not logged in
  else {
    setAuthenticated();
    setAnyUnlicensed();
    $("#menu-subscription, #menu-activate-license, #menu-second-divider").hide();
    $("#avatar").removeClass("avatarLicensed").html("<i class=\"ms-Icon ms-Icon--Contact\"></i>").addClass("avatarUnlicensed");
    $("#existing-user-message").empty().text("Sign into PowerPoint.").addClass("link").off("click", navigateToPage).click( () => openURL(t.urls.microsoftSigninSupport))
  }
}
function setAnyLicensed(userData) {
  $("#buy-now, #menu-buy-now, #menu-activate-license, #menu-second-divider").hide();
  $("#menu-subscription").show();
  $("#subscription-value,#subscription-value-expired").text(userData.subscription.id);
  $("#expiry-date").text(isoDateStringToLocalPrettyDateString(userData.subscription.expiryTime));
  $("#avatar").removeClass("avatarUnlicensed").text(() => getInitials(userData.name)).addClass("avatarLicensed");
  $("#menu-subscription").removeClass("linkHasPointer").addClass("linkAuto");
}

function setAnyUnlicensed() {
  $("#chunk-menu-button").off("click",toggleChunkMenu);
  $("#tooltip-chunk-text").empty().html(t.chunkUnlicensed);
  $("#menu-subscription").off("click", navigateToPage)
}

function setAuthenticated() {
  $("#buy-now, #menu-buy-now").show();
  $("#menu-subscription").hide();
  $("#btn-insert-graphic").off("click", onClickInsertGraphic).off("click", onClickInsertGraphicExpiredLicense).on("click", onClickInsertGraphicNotLicensed);
  $("#icon-close-validate-page").click(closeDialog);
}
function updateSelectionMode() {
  $("#copy-selected-ids-button").toggle(selectionMode);
  const checkboxes = $(".selectCheck");
  if (selectionMode) {
    checkboxes.addClass("flexed");
  } else {
    checkboxes.removeClass("flexed");
  }
}

function getCurrentPage() {
  for (const page of pages) {
    if ($("#page-" + page.name).is(":visible")) return page;
  }
  return null;
}

function getPageByName(pageName) {
  return pages.filter(page => page.name == pageName)[0];
}

function navigateToPage(newPageName) {
  const oldPage = getCurrentPage();
  const newPage = getPageByName(newPageName);
  if (newPage.isDialog) {
    dialogStack.push(oldPage);
  }
  else {
    // Jumping to anything that isn't a dialog means we need to forget the dialog stack.
    dialogStack = [];
    // When navigating anywhere via top nav, hide the filter options and reset category filtering
    // to "all".
    const searchMode = getSearchMode($("#search-box-input").val());
    if (newPage.name === "search" || (newPage.name === "results" && searchMode !== "normal")) {
      toggleFilter(false);
      setSearchFilter("filter-all");
    }
  }
  showPage(oldPage, newPage);
}

function showPage(oldPage, newPage) {
  if (oldPage.name === "search" && newPage.name === "results") {
    $('#main-search-area').insertBefore('#results-search').css("align-items","flex-start");
    $('#search-image-container').hide();
    $('#btn-filter').css("display","inline-flex");
    $('#cancel-indicator').show();
    searchBoxPlaceholderText = $("#search-box-input").attr("placeholder");
    $('#search-box-input').css("width","calc(100% - 40px)").removeAttr("placeholder");
    $('#menu-recent-search').css("width","calc(100% - 40px)");
    isSearchBoxOnResultsPage = true;
  }
  if (newPage.name === "search" && isSearchBoxOnResultsPage) {
    $("#main-search-area").insertBefore("#search-tip").css("align-items", "normal");
    $("#search-image-container").show();
    $("#btn-filter").hide();
    $("#cancel-indicator").hide();
    $('#search-box-input').css("width", "100%").attr("placeholder", searchBoxPlaceholderText);
    $('#menu-recent-search').css("width", "100%");
    isSearchBoxOnResultsPage = false;
  }
  if (newPage.name === "search") {
    $("#search-box-input").val("");
  }
  if (oldPage.name ==="results") {
    scrollPos = $("#page-content-container").scrollTop();
  }
  for (const page of pages) {
    if (page.name === newPage.name) {
      $("#page-" + page.name).show();
    } else {
      $("#page-" + page.name).hide();
    }
  }
  if (newPage.name === "results") {
    $("#page-content-container").scrollTop(scrollPos || 0);
    updateSelectionMode();
  }
}

function closeDialog() {
  showPage(getCurrentPage(), dialogStack.pop());
}

//Show hide main menu
function toggleMenu() {
  $("#header-menu").toggle();
}
//Hide menu when click outside of it
$(document).mouseup(function(e) 
{
  const tooltips = $("fluent-tooltip");
  const menus = [
    {button: $("#menu-button"),       menu: $("#header-menu")},
    {button: $("#chunk-menu-button"), menu: $("#chunk-menu")},
    {button: $("#search-box-input"), menu: $ ("#menu-recent-search")}
  ];
  for (const menuElements of menus) {
    if (!menuElements.menu.is(e.target) && !menuElements.button.is(e.target) && menuElements.menu.has(e.target).length === 0 && menuElements.button.has(e.target).length == 0) {
      menuElements.menu.hide();
    }
  }
  // hide tooltips on any click
  tooltips.each(function(i) {
      if (this._tooltipVisible) {
        this.hideTooltip();
      }
  });  
});
//show recent menu when click in empty search input
function onClickSearchInput(){
  var inputValue = $("#search-box-input").val();
  const recentMenu = $("#menu-recent-search");
  if (inputValue.length == 0 && recent.searches().length + recent.graphics().length != 0){
    recentMenu.show();
  }
}
//hide recent menu input has changed
function searchInputChanged(){
  const recentMenu = $("#menu-recent-search");
    recentMenu.hide();
}
//Show hide chunk menu
function toggleChunkMenu() {
  $("#chunk-menu").toggle();
}
function openURL(url){
  window.open(url);
}
function setShowKeywords(show) {
  const keywordDiv = $("#keyword-area");
  const buttonShowHide = $("#btnShowHide");
  keywordDiv.css({overflow:"visible", height:"100%"});
  const keywordHeight = keywordDiv.height();
  const keywordMinHeight = 98;
  buttonShowHide.empty();
  if (show) {
    buttonShowHide.append($("<i></i>").addClass("ms-Icon ms-Icon--ChevronUp bolder"));
      }
  else if (keywordHeight >= keywordMinHeight) {
    keywordDiv.css({overflow: "hidden", height: `${keywordMinHeight}px`});
    buttonShowHide.append($("<i></i>").addClass("ms-Icon ms-Icon--ChevronDown bolder"));
  }
}

function toggleKeywords() {
  setShowKeywords($("#keyword-area").css("overflow") === "hidden");
}

//clear search text
function clearSearchText(){
  $("#search-box-input").val("");
}

// Show/hide search filter area
// NOTE: Can be called as toggleFilter(true) or toggleFilter(false) to set a specific state instead
// of toggling
function toggleFilter() {
  const $filterSurround = $("#filter-surround");
  $filterSurround.toggle(...arguments);
  
  const $icon = $("#btn-filter i");
  if ($filterSurround.is(":visible")) {
    $icon.removeClass("ms-Icon--Filter").addClass("ms-Icon--FilterSolid").css("color", "var(--accent)");
  } else {
    $icon.removeClass("ms-Icon--FilterSolid").addClass("ms-Icon--Filter");
  }
}

//set search filter
function onClickSearchFilter(e){
  setSearchFilter(e.target.id);
  filterSearchResults(e.target.id);
}

function setSearchFilter(labelId) {
  const $filterLabel = $(`#${labelId}`);
  const $filters = $(".filterLabel");
  $filters.removeAttr("checked");
  $filterLabel.attr("checked", "checked");
}

function filterSearchResults(labelId) {
  const $filterLabel = $(`#${labelId}`);
  const mask = $filterLabel.attr("data-categorymask");
  let resultCount = 0, exactCount = 0;
  const cols = $(".col");
  cols.each(function() {
    const div = $(this);
    div.toggle((div.attr("data-category") & mask) != 0);
    if (div.is(":visible")) {
      resultCount++;
      if (div.attr("data-exactmatch") == "true") exactCount++;
    }
  });
  setResultsState(cols.length, resultCount, exactCount, getSearchMode($("#search-box-input").val()));
}

function getSearchMask() {
  return parseInt($(".filterLabel[checked]").attr("data-categorymask"));
}
function setResultsState(totalResults, filteredResults, filteredExactResults, mode) {
  if (mode === "bookmarks" && totalResults === 0) {
    $("#no-bookmarks").show();
    $("#no-results, #results-count, #end-results-message, #no-filtered-results, #some-inexact-results, #only-inexact-results, #clear-bookmarks").hide();
  }
  else if (totalResults === 0) {
    $("#no-results").show();
    $("#clear-bookmarks,#no-bookmarks, #results-count, #end-results-message, #no-filtered-results, #some-inexact-results, #only-inexact-results").hide();
  }
  else {
    if (filteredResults === 0) {
      $("#no-filtered-results").show();
      $("#no-bookmarks, #results-count, #end-results-message, #some-inexact-results, #only-inexact-results").hide();
    }
    else {
      const resultsCountStr = filteredResults == 1 ? "1 graphic" : `${filteredResults} graphics`;
      const fullResultsCountStr = resultsCountStr + (mode === "normal" ? ` (${filteredExactResults} x 100% match)` : "");
      $("#results-count").empty().text(fullResultsCountStr);
      $("#some-inexact-results").toggle(filteredExactResults < filteredResults && filteredExactResults > 0);
      $("#only-inexact-results").toggle(filteredExactResults == 0);
      $("#no-bookmarks, #no-filtered-results, #no-results").hide();
      $("#results-count").show();
      $("#end-results-message").toggle(mode !== "bookmarks");
    }
    $("#clear-bookmarks").toggle(mode === "bookmarks");
  }
}

//Copy ID to clipboard
// Clipboard API supported?
async function copyIdtoClipboard() {
  const idText = $("#copy-id-text").text().replace(/[^\d]+/g, "");
  // copy text to clipboard
  if (navigator.clipboard && navigator.clipboard.writeText) {
    await navigator.clipboard.writeText(`#${idText}`);
  }
    // Show the alert span
    $("#copy-id-to-clipboard-alert").css("display", "inline-block");
    // Hide the alert span after 3 seconds
    setTimeout(function() {
      $("#copy-id-to-clipboard-alert").css("display", "none");
    }, 3000);
}

//Send search query to the database
async function searchDatabase() {
  lastSelectedIndex = null;
  let searchQuery = $("#search-box-input").val();
  const mode = getSearchMode(searchQuery);
  if (mode === "bookmarks") {
    searchQuery = bookmarks.graphics().map(id => `#${id}`).join(" ");
  }
  if (mode === "bookmarks" && searchQuery === "") {
    // There are no bookmarks so just load an empty set of results without running a search.
    loadSearchResults([], mode);
  }
  else if (searchQuery !== "") {
    let result = null;
    toggleWait();
    try {
      result = await backEnd.search(searchQuery);
    }
    finally {
      toggleWait();
    }
    // add to recent searches, if required
    if (mode === "normal") recent.addSearch(searchQuery);
    $("#no-results-search-term, #no-results-filtered-search-term").empty().text(searchQuery);
    loadSearchResults(result.graphics, mode);
  }
  else {
    // do nothing with an empty search query
  }
  updateSelectionMode();
}

function loadSearchResults(graphics, mode) {
  const resultCount = graphics.length;
  // Clear out search results
  const exactContainer = $("#thumbnail-exact-results");
  const inexactContainer = $("#thumbnail-inexact-results");
  exactContainer.empty();
  inexactContainer.empty();
  // Generate HTML for search results
  const searchMask = getSearchMask();
  let filteredCount = 0, filteredExactCount = 0;
  for (const graphic of graphics) {
    const img = $("<img></img>").attr({src: graphic.thumbnailUri, "data-graphicuri": graphic.graphicUri, loading: "lazy", draggable: "false"}).click(onClickSearchResult);
    const checkbox = $("<input>").attr({type: "checkbox", class: "selectCheck", "data-graphicid": graphic.id}).on("click", onSelectOrDeselectThumbnail);
    const div = $("<div></div>").attr("data-category", graphic.category).attr("data-exactmatch", graphic.isExactMatch).addClass("col").append(checkbox).append(img);
    (graphic.isExactMatch ? exactContainer : inexactContainer).append(div);
    if ((graphic.category & searchMask) == 0) {
      div.hide();
    } else {
      filteredCount++;
      if (graphic.isExactMatch) filteredExactCount++;
    }
  }
  setResultsState(resultCount, filteredCount, filteredExactCount, mode);
  navigateToPage("results");
}

function getSearchMode(searchQuery) {
  switch (searchQuery) {
    case "#bookmarks":
      return "bookmarks";
    case "#new":
      return "new";
    default:
      return "normal";
  }
}

function onSelectOrDeselectThumbnail(e) {
  if (this.checked) {
    const allCheckboxes = $(".selectCheck");
    const selectedIndex = allCheckboxes.index(this);
    if (!e.shiftKey) {
      lastSelectedIndex = selectedIndex;
    } else {
      const startIndex = Math.min(lastSelectedIndex, selectedIndex);
      const endIndex = Math.max(lastSelectedIndex, selectedIndex);
      const categoryMask = $($(".filterLabel[checked]")[0]).data("categorymask");
      for (let  i = startIndex; i <= endIndex; i++) {
        const category = $(allCheckboxes[i]).parent().data("category");
        if ((category & categoryMask) != 0) allCheckboxes[i].checked = true;
      }
    }
  }
}

// update UI for recent searches/inserted graphics
function updateRecentUI(){
  const recentTitle = $("#recent-search-title");
  const recentMenuItems = $(".recentItem");
  recentMenuItems.remove();
  let previousElement = recentTitle;
  for(const search of recent.searches()) {
    previousElement.after(previousElement = $("<fluent-menu-item></fluent-menu-item>").addClass("recentItem").click(onClickRecent).text(search));
  }
  $(".recentSearchElement").toggle(recent.searches().length != 0);
  $(".recentGraphicElement").toggle(recent.graphics().length != 0);
}

async function onClickRecentlyInsertedGraphics() {
  const query = recent.graphics().map(id => `#${id}`).join(" ");
  $("#search-box-input").val(query);
  await searchDatabase();
}

async function onClickSearchResult(e) {
  const clickedResult = $(e.target);
  if (selectionMode) {
    const checkbox = clickedResult.parent().children('.selectCheck');
    checkbox.prop('checked', !checkbox.prop('checked'));
    onSelectOrDeselectThumbnail.call(checkbox[0], e);
    return;
  }
  // Set the preview to the thumbnail initially before lazy loading the preview
  $("#graphic-preview").attr("src", clickedResult.attr("src"));
  let details = null;
  toggleWait();
  try {
    details = await backEnd.fetch(clickedResult.attr("data-graphicuri"));
  }
  finally {
    toggleWait();
  }
  // Start lazy loading the preview
  $("#graphic-preview").attr("src", details.previewUri);
  $("#copy-id-text").text(`ID: #${details.id}`);
  $("#btn-insert-graphic").attr({"data-leaseuri": details.leaseUri, "data-graphicid": details.id});
  updateBookmarkIconAndText(details.id);
  const keywordArea = $("#keyword-area");
  keywordArea.empty();
  for (const keyword of details.keywords) {
    keywordArea.append($("<div></div>").addClass("keywordCol").append($("<fluent-button></fluent-button>").attr({appearance: "stealth"}).text(keyword).click(onClickKeyword)));
  }
  navigateToPage("graphic-details");
  setShowKeywords(false);
}

async function onClickKeyword(e) {
  scrollPos = 0;
  let keyword = $(e.target).text();
  if (keyword.includes(" ")) keyword = `"${keyword}"`;
  $("#search-box-input").val(keyword);
  await searchDatabase();
}

async function onClickRecent(e) {
  let recent = $(e.target).text();
  $("#search-box-input").val(recent);
  await searchDatabase();
  $("#menu-recent-search").toggle();
}

async function onClickInsertGraphic(e) {
  const button = $(e.target);
  recent.addGraphic(button.attr("data-graphicid"));
  toggleWait();
  try {
    let lease = null;
    try {
      lease = await backEnd.fetch(button.attr("data-leaseuri"), true);
    }
    catch (e) {
      // If that failed with 401 Unauthorised, we can assume the subscription has expired since
      // the add-in was last refreshed - otherwise they wouldn't have got here in the first place.
      if (e.status == 401) {
        // Refresh the licensing state and show the "expired" dialog.
        setLicensed(await backEnd.whoami());
        navigateToPage("expired-license");
        return;
      }
      else {
        throw e;
      }
    }
    const graphicBlob = await backEnd.fetch(lease.downloadUri);
    await PowerPoint.run(async function(context) {
      const slideRange = await OfficeWrapper.getSelectedDataAsync(Office.CoercionType.SlideRange);
      const currentSlideId = `${slideRange.slides[0].id}#`;
      context.presentation.insertSlidesFromBase64(graphicBlob, {
        formatting: PowerPoint.InsertSlideFormatting.useDestinationTheme,
        targetSlideId: currentSlideId
      });
      await context.sync();
      await OfficeWrapper.goToByIdAsync(Office.Index.Next, Office.GoToType.Index);
      await context.sync();
    });
  }
  finally {
    toggleWait();
  }
}
function onClickInsertGraphicNotLicensed(){
  navigateToPage("not-licensed");
}

function onClickInsertGraphicExpiredLicense () {
  navigateToPage("expired-license");
}

function onClickMenuItem(e) {
  const staticOption = $("#menu-subscription");
  if(!staticOption.is(e.target) && staticOption.has(e.target).length === 0) {
    $(e.target).parent().hide();
  }
}

async function onClickActivateLicense() {
  const code = $("#input-validate-license").val();
  const errorContainer = $("#activate-errors");
  toggleWait();
  try {
    await backEnd.activateLicense(code);
    location.reload();
  }
  catch {
    errorContainer.text("Please enter a valid license code");
    messageDisappear(errorContainer, 4000);
  }
  finally {
    toggleWait();
  }
}

function showRandomTips(){
  var randomTip = t.tips[Math.floor(Math.random()*t.tips.length)];
  $("#tip-description").html(randomTip);
}
function getInitials (name) {
  var parts = name.split(' ')
  var initials = ''
  for (var i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0]
    }
  }
  if (initials != "") {
  //Only return the first and last characters, just in case three names are present
  return initials.slice(0,1) + initials.slice(-1);
  }
  else {
    //If we don't get a name, display something so the menu works
    return "???"
  }
}
function toggleWait () {
  $("#waiting-state").toggle();
}
function isoDateStringToLocalPrettyDateString(isoString) {
  const localDate = new Date(isoString);
  const dayOfMonth = localDate.getDate();
  const monthName = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"][localDate.getMonth()];
  const year = localDate.getFullYear();
  return `${dayOfMonth} ${monthName} ${year}`;
}
function messageDisappear(element, timePeriod) {
  element.show();
  setTimeout(function(){
  element.text("");
  }, timePeriod);
}

function onClickExpiredMenuItem () {
  navigateToPage("expired-license");
  toggleMenu()
}
//Hide chunk tooltip when menu is open
$("#chunk-menu-button").mousemove(function(){
  $("#chunk-tooltip").toggle(!$("#chunk-menu").is(":visible"));
});

async function onClickWhatsNew(e) {
  $("#search-box-input").val("#new");
  await searchDatabase();
}

async function onClickBookmarks(e) {
  $("#search-box-input").val("#bookmarks");
  await searchDatabase();
}

async function onClickBackToSearchResults(e) {
  if ($("#search-box-input").val() === "#bookmarks") {
    // We're showing bookmarks so we need to re-run the search in case the user un-bookmarked
    // the current graphic.
    await searchDatabase();
  }
  else {
    // Not showing bookmarks so we can just hop back to search results.
    navigateToPage("results");
  }
}

async function onClickCopySelectedIds() {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    const checkedIds = $(".selectCheck:checked").map(function(){ return $(this).attr("data-graphicid") }).get();
    await navigator.clipboard.writeText(checkedIds.join(" "));
  }
}

function populateGraphicTypes(...elementIds) {
  // Sort graphicTypeMappings by graphicType in alphabetical order
  const sortedMappings = graphicTypeMappings.slice().sort((a, b) => {
      return a.graphicType.localeCompare(b.graphicType);
  });

  // Loop over each provided element ID
  elementIds.forEach(elementId => {
    const graphicTypesArea = $(`#${elementId}`);
    graphicTypesArea.empty(); // Clear any existing content

    // Populate the graphic types area with buttons
    sortedMappings.forEach(mapping => {
      const searchTerm = mapping.searchTerm || mapping.graphicType;
      const button = $("<fluent-button></fluent-button>")
        .attr({ appearance: "stealth" })
        .text(mapping.graphicType)
        .click(async () => {
          await onClickGraphicTypes(searchTerm);
        });
        const categoryDiv = $("<div></div>").addClass("keywordCol").append(button);
        graphicTypesArea.append(categoryDiv);
      });
  });
}

async function onClickGraphicTypes(searchTerm) {
  // Replace the search query with the selected graphic type
  $("#search-box-input").val(searchTerm);
  // Call the search function
  await searchDatabase();
}

function setMaxHeightByRows(numRowsToShow, $element) {
  const $buttons = $element.find(".keywordCol");

  // Check if there are any buttons before proceeding
  if ($buttons.length === 0) {
    console.error("No buttons found in the specified element.");
    return;
  }

  // Calculate the top positions to determine row boundaries
  let rowPositions = [];
  $buttons.each(function() {
    const topPosition = $(this).position().top;
    if (!rowPositions.includes(topPosition)) {
      rowPositions.push(topPosition);
    }
  });

  // Calculate the height needed for the specified number of rows
  let maxHeight = 0;
  for (let i = 0; i < numRowsToShow && i < rowPositions.length; i++) {
    maxHeight += $buttons.filter(function() {
      return $(this).position().top === rowPositions[i];
    }).outerHeight(true);
  }

  // Set the element's max-height to the calculated height
  $element.css({
    "max-height": `${maxHeight}px`,
    "overflow": "hidden"
  });
}
function toggleGraphicTypesExpanded(rows) {
  const $element = $("#keyword-area-graphic-types");
  const $button = $("#btnShowHideGraphicTypes > i");

  $button.removeClass("ms-Icon--ChevronDown ms-Icon--ChevronUp");
  if ($element.css("max-height") === "none" || $element.css("max-height") === "") {
    // If it's currently expanded, collapse it
    setMaxHeightByRows(rows, $element);
    $element.css('max-height', `${$element.data('collapsed-height')}px`);
    $button.addClass("ms-Icon--ChevronDown");
  } else {
    // If it's currently collapsed, expand it
    $element.data('collapsed-height', $element.css("max-height"));
    $element.css('max-height', 'none'); // Remove the max-height restriction
    $button.addClass("ms-Icon--ChevronUp");
  }
}

function updateBookmarkIconAndText(id)
{
  const notBookmarkedIconClass = "ms-Icon--SingleBookmark";
  const bookmarkedIconClass = "ms-Icon--SingleBookmarkSolid";
  const bookmarkText = "Bookmark";
  const bookmarkedText = "Bookmarked";
  const isBookmarked = bookmarks.hasGraphic(id);
  const $bookmarkIcon = $("#bookmark-graphic i");
  const $bookmarkText = $("#bookmark-graphic-text");
  $bookmarkIcon.removeClass(`${notBookmarkedIconClass} ${bookmarkedIconClass}`);
  $bookmarkIcon.addClass(isBookmarked ? bookmarkedIconClass : notBookmarkedIconClass);
  $bookmarkText.text(isBookmarked ? bookmarkedText : bookmarkText);
}
