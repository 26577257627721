import env from './environment.js';
import jwt_decode from 'jwt-decode';

export class BackEnd {
    async getToken() {
        const token = await Office.auth.getAccessToken({allowConsentPrompt: true});
        return token;
    }

    async sendRequest(uri, method, authenticate = false) {
        let headers = {};
        if (authenticate) headers.Authorization = `Bearer ${await this.getToken()}`;
        return $.ajax({type: method, url: uri, headers: headers});
    }

    async fetch(uri, authenticate = false) {
        return await this.sendRequest(uri, 'GET', authenticate);
    }

    async activateLicense(code) {
        return await this.sendRequest(`${env.backend}api/activate/${code}`, 'POST', true);
    }
    async search(query) {
        return await this.fetch(`${env.backend}api/search?q=${encodeURIComponent(query)}`);
    }

    async whoami() {
        try {
            return await this.fetch(`${env.backend}api/whoami`, true);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}
