import { OfficeWrapper } from './office-wrapper.js';

export class Chunking {
    static isSupported() {
        // Adding shapes requires PowerPointApi version >= 1.4
        return Office.context.requirements.isSetSupported("PowerPointApi", "1.4");
    }
    static async chunkSelectionBy(chunkType) {
      const newStyle = Office.context.requirements.isSetSupported('PowerPointApi', '1.5');
      await PowerPoint.run(async (context) => {
        let top, text, selectedShape, font;
        if (newStyle) {
          const selectedShapes = context.presentation.getSelectedShapes();
          const shapeCountResult = selectedShapes.getCount();
          await context.sync();
          if (shapeCountResult.value != 1) return;
          selectedShapes.load("items");
          await context.sync();
          selectedShape = selectedShapes.items[0];
          selectedShape.load({select: ["top", "textFrame/textRange/text"], expand: "textFrame/textRange/font"});
          await context.sync();
          const textRange = selectedShape.textFrame.textRange;
          font = textRange.font;
          top = selectedShape.top;
          text = textRange.text;
        }
        else {
          selectedShape = null;
          top = 0;
          text = (await OfficeWrapper.getSelectedDataAsync(Office.CoercionType.Text)).trim();
          font = null;
        }
        const shapes = await Chunking.#getSlideShapes(context);
        const chunks = Chunking.#getChunks(text, chunkType);
        if (chunks.length === 0 || (chunks.length === 1 && chunks[0].length === 0)) return;
        // This is grim but we STILL (as of API 1.5) can't get the actual slide width so we use
        // the default width of 960.
        const slideWidth = 960;
        const chunkWidth = slideWidth / chunks.length;
        let left = 0;
        for (const chunk of chunks) {
          let newBox = shapes.addTextBox(chunk);
          newBox.left = left;
          newBox.top = top;
          newBox.width = chunkWidth;
          if (font !== null) {
            for (const propName of ["name", "size", "color", "bold", "italic", "underline"]) {
              // Documentation says if the range contains a mixture of values for a particular
              // property, the value of that property will be null. However, I've also seen the
              // "color" property set to an empty string and "size" set to 0 so we test for all of
              // those possibilities.
              if (font[propName] !== null && font[propName] !== "" && font[propName] !== 0) {
                newBox.textFrame.textRange.font[propName] = font[propName];
              }
            }
          }
          await context.sync();
          newBox.textFrame.autoSizeSetting = "AutoSizeShapeToFitText";
          await context.sync();
          left += chunkWidth;
        }
        if (newStyle) {
          selectedShape.delete();
          await context.sync();
        }
      });
    }
    static #getChunks(text, chunkType) {
      let chunks = [];
      if (chunkType === "sentence") {
        // Strip out any newline characters and remove leading/trailing whitespace.
        // Somewhat inexplicably, paragraphs in placeholders are separated by vertical tab
        // characters instead of CR and/or LF.
        let stripped = text.replace(/[\v\r\n]/, " ").trim();
        let match;
        do {
          // The end of a sentence is delimited by ".", "?" or "!" followed by whitespace.
          match = stripped.match(/[?!.]\s+/);
          if (match) {
            // Add the chunk, preserving the delimiter but not the trailing whitespace.
            if (match.index > 0) chunks.push(stripped.substring(0, match.index + 1));
            // Remove the chunk, delimiter and whitespace from the string ready to go again.
            stripped = stripped.substring(match.index + match[0].length);
          }
        } while (match);
        // Make a final chunk out of anything that's left over.
        if (stripped.length > 0) chunks.push(stripped);
      }
      else {
        // The logic for chunking by paragraphs and bullets is the same - just split on newline
        // and vertical tab characters.
        chunks = text.split(/(?:\v)|(?:\r|\n|\r\n)/).filter(chunk => chunk.trim().length > 0);
      }
      return chunks;
    }
    static async #getSlideShapes(context) {
      // NOTE: The slide indices in a SlideRange are 1-based...
      const slideIndex = (await OfficeWrapper.getSelectedDataAsync(Office.CoercionType.SlideRange)).slides[0].index;
      // But this API wants 0-based indices for some reason. Yay.
      return context.presentation.slides.getItemAt(slideIndex - 1).shapes;
    }
}
