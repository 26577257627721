import { RecentCache } from './recent-cache.js';

const STORAGE_NAME = "BagRecent";

export class Recent {
    #searches = null;
    #graphics = null;
    #changeHandlers = [];
    constructor() {
        this.#init([], []);
    }
    addSearch(query) {
        this.#searches.add(query);
        this.#processChange();
    }
    addGraphic(id) {
        this.#graphics.add(id);
        this.#processChange();
    }
    clearSearches() {
        this.#searches.clear();
        this.#processChange();
    }
    load() {
        let json = null;
        try {
            json = window.localStorage.getItem(STORAGE_NAME);
        }
        catch (e) {} // localStorage doesn't work in incognito mode so ignore
        if (json !== null) {
            const parsed = JSON.parse(json);
            this.#init(parsed.searches, parsed.graphics);
        } else {
            this.#init([], []);
        }
        this.#processChange();
    }
    onChange(fn) {
        this.#changeHandlers.push(fn);
    }
    graphics() {
        return Array.from(this.#graphics);
    }
    searches() {
        return Array.from(this.#searches);
    }
    #init(searches, graphics) {
        this.#searches = new RecentCache(searches, 5);
        this.#graphics = new RecentCache(graphics, 20);
    }
    #processChange() {
        this.#save();
        this.#changeHandlers.forEach((handler) => handler());
    }
    #save() {
        try {
            window.localStorage.setItem(STORAGE_NAME, JSON.stringify({searches: this.searches(), graphics: this.graphics()}));
        }
        catch (e) {} // localStorage doesn't work in incognito mode so ignore
    }
}

