export class OfficeWrapper {
    /**
     * Reads the data contained in the current selection in the document.
     * @param {Office.CoercionType} coercionType
     * @see {@link Office.Document.getSelectedDataAsync}
     */
    static getSelectedDataAsync(coercionType) {
        return OfficeWrapper.#wrapInPromise((handler) => Office.context.document.getSelectedDataAsync(coercionType, handler));
    }

    /**
     * Goes to the specified object or location in the document.
     * @param {string|number} id
     * @param {Office.GoToType} goToType
     * @see {@link Office.Document.goToByIdAsync}
     */
    static goToByIdAsync(id, goToType) {
        return OfficeWrapper.#wrapInPromise((handler) => Office.context.document.goToByIdAsync(id, goToType, handler));
    }

    static #wrapInPromise(fn) {
        return new OfficeExtension.Promise(function(resolve, reject) {
        const handleAsyncResult = function(asyncResult) {
            try {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                reject(console.error(asyncResult.error.message));
            }  else {
                resolve(asyncResult.value);
            }
            }
            catch (error) {
            reject(console.log(error));
            }
        };
        fn(handleAsyncResult);
        });
    }
}
