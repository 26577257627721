export const graphicTypeMappings = [
    { graphicType: "Icons and symbols", searchTerm: "Icons" },
    { graphicType: "Bar chart" }, 
    { graphicType: "Bridge" }, 
    { graphicType: "Building block" }, 
    { graphicType: "Calendar", searchTerm: "calendar graphic" }, 
    { graphicType: "Chain", searchTerm:"chain link" }, 
    { graphicType: "Conveyor belt" }, 
    { graphicType: "Cutaway diagram", searchTerm:"cutaway" }, 
    { graphicType: "DNA" }, 
    { graphicType: "Floor plan" }, 
    { graphicType: "Funnel", searchTerm:"funnel graphic" }, 
    { graphicType: "Gantt", searchTerm:"Gantt schedule" }, 
    { graphicType: "Gauge", searchTerm:"Gauge dashboard" }, 
    { graphicType: "Gear", searchTerm:"gear process" }, 
    { graphicType: "Hexagon", searchTerm:"Hexagon polygon" }, 
    { graphicType: "Hub and spoke", searchTerm: "Hub spoke" },
    { graphicType: "Looping", searchTerm:"looping repeat" },
    { graphicType: "Map" },
    { graphicType: "Org chart" },
    { graphicType: "Peg", searchTerm:"peg connect" },
    { graphicType: "Pie chart" },
    { graphicType: "Pipe", searchTerm:"pipe connect" },
    { graphicType: "Process", searchTerm:"process flow" },
    { graphicType: "Puzzle", searchTerm:"puzzle attach" },
    { graphicType: "Pyramid", searchTerm:"pyramid levels" },
    { graphicType: "Risk matrix" },
    { graphicType: "Road", searchTerm:"road path" },
    { graphicType: "Scale", searchTerm: "scale compare" },
    { graphicType: "Spiral", searchTerm:"spiral twist" },
    { graphicType: "Stair", searchTerm:"stair steps" },
    { graphicType: "Table", searchTerm: "matrix" },
    { graphicType: "Tear sheet", searchTerm: '"tear sheet"' },
    { graphicType: "Temple", searchTerm:"temple building" },
    { graphicType: "Timeline" },
    { graphicType: "Tree diagram", searchTerm: "tree grow" },
    { graphicType: "Venn diagram", searchTerm:"venn combine" },
    { graphicType: "Waterfall diagram" },
    { graphicType: "Zipper", searchTerm:"zipper merge" },
    { graphicType: "Isometric" },
    { graphicType: "Dashboard" },
    { graphicType: "Stacked", searchTerm:"stacked layer" },
    { graphicType: "Line chart", searchTerm: "line chart" },
    { graphicType: "Dome", searchTerm: "dome protect" },
    { graphicType: "Radar chart", searchTerm: '"radar chart"' },
    { graphicType: "Area chart", searchTerm: "area chart" },
    { graphicType: "Fishbone", searchTerm:"fishbone cause effect" },
    { graphicType: "Vee diagram", searchTerm: "vee process" }
];