export class RecentCache {
    #list = [];
    #maxItems = 0;
    constructor(items, maxItems) {
        this.#list = items;
        this.#maxItems = maxItems;
    }
    add(item) {
        this.#list = ([item].concat(this.#list.filter(entry => entry != item))).slice(0, this.#maxItems);
    }
    clear() {
        this.#list = [];
    }
    [Symbol.iterator]() {
        let index = -1, list = this.#list;
        return {
            next: () => ({ value: list[++index], done: !(index in list) })
        };
    }
}
