let strings = {
    tips:[
        "Every graphic is 100% editable with tiny file sizes. Modify, customize, and animate in any way.",
        "Use 'Chunking' to build process diagrams, add supporting imagery/icons, and make graphics using isometric modules.",
        "Search for pre-built graphics with words like transition, cooperation, or time (i.e., your core message) to find graphics that are already built. Next, add your text into the existing placeholders.",
        "Searching keyphrases require quotation marks (e.g., \"supply chain management\").",
        "Select a text box and click the Chunk Text button to segment your content into smaller pieces (text boxes).",
        "Scale graphics while holding the Shift key to size proportionally and prevent distortion.",
        "Use Shape Fill and Shape Outline to quickly modify the style of your graphics.",
        "Use Ungroup to break apart any graphic. Be sure to Group it again before scaling to ensure consistent sizing.",
        "To flip your graphic, select it and choose Arrange > Rotate > Flip Horizontal or Flip Vertical.",
        "With an element selected, double click the Format Painter tool to apply a sampled style onto multiple objects.",
        "To evenly distribute objects, select all elements and choose Arrange > Align > Distribute Horizontally (or Distribute Vertically).",
        "To center objects, select all elements and choose Arrange > Align > Align Center. Next, choose Arrange > Align > Align Middle.",
        "All graphics can be animated in any way. Ungroup to break up your graphics.",
        "Graphics are grouped hierarchically. Each time you Ungroup, you can access sub groups of graphic elements. Be sure to Group it again before scaling to ensure consistent sizing."
        ],
    urls:{
        contactSupport : "https://billiondollargraphics.com/contact-us/",
        buyNow : "https://store.buildagraphic.com/products/build-a-graphic-subscription",
        customGraphic : "https://billiondollargraphics.com/custom/",
        licenseAgreement : "https://billiondollargraphics.com/EULA.pdf",
        howTo : "https://billiondollargraphics.com/build-a-graphic-tutorials/",
        chunkLearnMore : "https://billiondollargraphics.com/build-a-graphic/#chunktext",
        microsoftSigninSupport : "https://support.microsoft.com/en-us/office/sign-in-to-office-b9582171-fd1f-4284-9846-bdd72bb28426",
        suggestGraphics : "https://billiondollargraphics.com/request/",
        },
        //example of paramterised steing below 
    //searchboxPlaceholder: (graphicsTotal) => `Search ${graphicsTotal} graphics...`,   
};
strings.chunkLicensed = `<b>Chunk text</b><br>Break text in the selected shape into chunks.<br><a href="${strings.urls.chunkLearnMore}" target="_blank">Learn more</a>.`;
strings.chunkUnsupported = `<b>Chunk text</b><br>This feature is only available in M365 versions of Office. <a href="${strings.urls.contactSupport}" target="_blank">Contact us</a> for further information.`;
strings.chunkUnlicensed = `<b>Chunk text</b><br>This feature is only available to subscribers.<br><a href="${strings.urls.chunkLearnMore}" target="_blank">Learn more</a>.`;
strings.cantFindGraphic = `<p><b>Can't find what you want?</b></p><p><a href="${strings.urls.suggestGraphics}">Tell us what graphics you'd like to see</a>, or we can design a <a href="${strings.urls.customGraphic}">custom graphic</a> just for you.</p>`

export default strings;